import { toggleRefinement } from "instantsearch.js/es/widgets";

const toggleRefinementWidgets = [
  toggleRefinement({
    container: "#toggle-refinement-urgent_sale",
    attribute: "urgent_sale",
    templates: {
      labelText({ isRefined, count, onFacetValue, offFacetValue }, { html }) {
        return html`Urgent Sale<span class="ais-RefinementList-count badge">${onFacetValue.count > 0 ? onFacetValue.count : 0}</span>`;
      },
    },
    cssClasses: {
      label: "form-check",
      checkbox: "form-check-input",
    },
  }),
  toggleRefinement({
    container: "#toggle-refinement-investment_only",
    attribute: "investment_only",
    templates: {
      labelText({ isRefined, count, onFacetValue, offFacetValue }, { html }) {
        return html`Investment Only<span class="ais-RefinementList-count badge">${onFacetValue.count > 0 ? onFacetValue.count : 0}</span>`;
      },
    },
    cssClasses: {
      label: "form-check",
      checkbox: "form-check-input",
    },
  }),
];

export { toggleRefinementWidgets };